import axios, { AxiosResponse } from 'axios';
import postToBackground from 'utils/postToBackgound';

const KS_ENDPOINT = process.env.REACT_APP_KS_ENDPOINT as string;
const FRED_ENDPOINT = process.env.REACT_APP_FRED_ENDPOINT as string;
const AuthorizationHeader = 'Authorization';

export const fredClient = axios.create({
  baseURL: FRED_ENDPOINT,
  headers: {
    'X-Device-Id': window.navigator.userAgent,
  },
});
export const ksClient = axios.create({ baseURL: KS_ENDPOINT });
const clients = [ksClient, fredClient];
export const authorize = (token: string) => {
  ksClient.defaults.headers.common[AuthorizationHeader] = `Bearer ${token}`;
  fredClient.defaults.headers.common[AuthorizationHeader] = `Bearer ${token}`;
};

const resetToken = () => {
  postToBackground('RESET_TOKEN');
};

for (const client of clients) {
  client.interceptors.response.use(
    (resp) => resp,
    (error) => {
      const resp = error.response as AxiosResponse<any>;
      if (resp?.status === 401 || resp?.status === 403) {
        resetToken();
      }

      throw error;
    },
  );
}
