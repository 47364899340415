import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import logger from 'services/logger';
import App from './App';
import './index.css';

const rootFlag = 'kawaii-show-ext-web';

if (!document.querySelector(`#${rootFlag}`)) {
  const mountPoint = document.createElement('div');
  mountPoint.id = rootFlag;
  document.body.appendChild(mountPoint);
  ReactDOM.render(
    <RecoilRoot>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </RecoilRoot>,
    mountPoint,
  );
} else {
  logger.warn('prevent multiple inject');
}
