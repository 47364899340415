export const Events = {
  RESET_TOKEN: 'RESET_TOKEN',
  KS_IM_INFO: 'KS_IM_INFO',
};
export type Payloads = {
  RESET_TOKEN: any;
  KS_IM_INFO: {
    isMain: boolean;
    sellerNick?: string;
    assistantNick: string;
    assistantId?: string;
  };
};
const postToBackground = (event: keyof typeof Events, payload?: any) => {
  window.postMessage({ to: 'kawaiishow-ext-background', event, payload }, window.origin);
};
export default postToBackground;
