import React, { useEffect } from 'react';
import { ConfigProvider, Modal, message } from 'antd';
import { css } from '@emotion/css/macro';
import { report, EventTypes } from 'api/fredApi';
import { fetchGuaranteeConfig, fetchGuaranteeTemplate } from 'api/storeHome';
import setupIM from 'setupIM';
import MessageBar from './views/MessageBar';
import 'antd/dist/antd.less';
import FloatingLayer from './views/FloatingLayer';
import useAuthorization from './hooks/biz/useAuthorization';
import { ANTD_PREFIX } from './utils/getAntdClass';
import useKsState from './hooks/biz/useKsState';

message.config({ prefixCls: `${ANTD_PREFIX}-message` });
Modal.config({ rootPrefixCls: ANTD_PREFIX });
const styles = {
  app: css`
    &,
    *,
    *::before,
    *::after {
      // antd全局依赖样式，由于与快手界面冲突，全局unset，并在根节点scope内设置
      box-sizing: border-box;
    }
  `,
};
function App() {
  const { authed, auth } = useAuthorization();
  const { assistant } = useKsState();
  useEffect(() => {
    const cb = (messageEvent: MessageEvent) => {
      // eslint-disable-next-line no-console
      console.log('收到消息:', messageEvent);
      if (messageEvent.source !== window) {
        try {
          // 如果window被Proxy了，再比较一层document
          if ((messageEvent.source as Window)?.document !== window.document) {
            return;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('获取 document 错误:', error);
        }
      }

      const {
        data: { event, payload, to },
      } = messageEvent;
      if (to !== 'kawaiishow-web') {
        return;
      }
      const Events = {
        INJECT_TOKEN: 'INJECT_TOKEN',
      };
      switch (event) {
        case Events.INJECT_TOKEN: {
          auth(payload.token);
        }
      }
    };
    window.addEventListener('message', cb, false);
    return () => {
      window.removeEventListener('message', cb);
    };
  }, [auth, authed]);
  useEffect(() => {
    if (authed) {
      report({
        event_type: EventTypes.KS_EXTENSION_LOAD,
      });
      return setupIM(() => {
        return Promise.all([fetchGuaranteeConfig(), fetchGuaranteeTemplate()]).then(
          ([configResponse, templateResponse]) => {
            return {
              ...configResponse.data.data,
              template: templateResponse.data.data,
            };
          },
        );
      });
    }

    return () => {};
  }, [authed]);
  return (
    <ConfigProvider prefixCls={ANTD_PREFIX}>
      <div className={styles.app}>
        {authed && assistant && (
          <>
            <MessageBar />
            <FloatingLayer />
          </>
        )}
      </div>
    </ConfigProvider>
  );
}

export default App;
