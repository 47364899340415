import {
  clearAllNoticeMessageByAssistantNick,
  deleteNoticeMessageById,
  fetchNoticeMessage,
  NotificationItem,
} from 'api/messageApi';
import useNoticeMessagState from 'hooks/biz/useNoticeMessagState';
import { useCallback, useEffect, useRef } from 'react';
import logger from 'services/logger';
import useKsState from 'hooks/biz/useKsState';
import { message } from 'antd';

const INTERVAL_FETCH_DELAY = 5000;
const filterMessageItemByBuyerName = (
  list: NotificationItem[],
  buyerNick?: string,
): NotificationItem[] => {
  if (!list || !buyerNick) {
    return list || [];
  }
  // eslint-disable-next-line no-console
  console.log('filterMessageItemByBuyerName list:', list);
  // eslint-disable-next-line no-console
  console.log('filterMessageItemByBuyerName buyerNick:', buyerNick);
  /* eslint-disable camelcase */
  let local_noticeMessage: any[] = [];
  const localNoticeMessage = localStorage.getItem('NoticeMessage');
  if (localNoticeMessage) {
    local_noticeMessage = local_noticeMessage.concat(JSON.parse(localNoticeMessage));
  }
  return list.filter((item) => {
    if (item.buyer_nick === buyerNick) {
      // 如果提醒消息里包含当前聊天窗的买家，过滤掉该条提醒
      local_noticeMessage.forEach((item, index, local_noticeMessage) => {
        if (item.buyer_nick === buyerNick) {
          local_noticeMessage.splice(index, 1);
        }
      });
      localStorage.setItem('NoticeMessage', JSON.stringify(local_noticeMessage));
      deleteNoticeMessageById(item.id).catch((e) => {
        // 因为会过滤掉当前在聊天的买家的提醒消息，不需要提醒，静默进行
      });
      return false;
    }
    return true;
  });
};
const useFloatingLayerState = () => {
  const { set: setNoticeMessage } = useNoticeMessagState();
  const { assistant, isSubAccount, currentBuyer } = useKsState();
  const assistantNick = assistant?.assistantNick;
  const buyerNick = currentBuyer?.nick;
  const buyerNickRef = useRef(buyerNick);
  useEffect(() => {
    buyerNickRef.current = buyerNick;
    if (buyerNick) {
      setNoticeMessage((list: NotificationItem[]) => {
        return filterMessageItemByBuyerName(list, buyerNick);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerNick]);
  useEffect(() => {
    let unmounted = false;
    let timeout: ReturnType<typeof setTimeout>;
    const fetch = async () => {
      if (assistantNick) {
        try {
          const resp = await fetchNoticeMessage(assistantNick, !isSubAccount);
          if (unmounted) {
            return;
          }
          let noticeMessage: any[] = [];
          const localNoticeMessage = localStorage.getItem('NoticeMessage');
          if (localNoticeMessage) {
            noticeMessage = noticeMessage.concat(JSON.parse(localNoticeMessage));
          }
          const result = filterMessageItemByBuyerName(
            resp.data.data.notice_reminds.concat(noticeMessage),
            buyerNick,
          );
          setNoticeMessage(result.sort((p, n) => n.update_time - p.update_time));
        } catch (e) {
          logger.warn('获取提醒列表失败');
        }
        timeout = setTimeout(fetch, INTERVAL_FETCH_DELAY);
      }
    };
    fetch();
    return () => {
      clearTimeout(timeout);
      unmounted = true;
    };
  }, [assistantNick, buyerNick, isSubAccount, setNoticeMessage]);

  const clearNoticeMessage = useCallback(() => {
    if (assistantNick) {
      clearAllNoticeMessageByAssistantNick(assistantNick, !isSubAccount)
        .then(() => {
          setNoticeMessage([]);
          localStorage.setItem('NoticeMessage', JSON.stringify([]));
          message.success('清空成功');
        })
        .catch((e) => {
          logger.warn(e);
          message.error('清空操作失败，请稍后再试');
        });
    }
  }, [assistantNick, isSubAccount, setNoticeMessage]);
  return { clearNoticeMessage };
};
export default useFloatingLayerState;
