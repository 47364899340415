/* eslint-disable class-methods-use-this */
import { getAssistant, sendTextByUserNick, sendImageByUserNick } from 'services/im';
import bus from './bus';
import logger from './logger';
import Agent from './agent';

// eslint-disable-next-line prefer-destructuring
const REACT_APP_IMAGE_BUCKET = process.env.REACT_APP_IMAGE_BUCKET;

class KSBOT {
  agent: null | Agent;

  constructor() {
    this.agent = null;
  }

  async start() {
    const assistant = await getAssistant();
    logger.info('assistant1:', assistant);
    try {
      await this.setupAgent();
    } catch (err) {
      logger.warn('setup Agent failed: %s', err);
    }
    this.listenEvents();
    logger.info('bot started');
  }

  async setupAgent() {
    const assistant = await getAssistant();
    logger.info('assistant2:', assistant);
    const agent = new Agent({
      endpoint: process.env.REACT_APP_WS_URL as string,
      shopID: assistant.sellerId,
      assistant: {
        id: assistant.userNick,
        name: assistant.userNick,
      },
    });
    await agent.connect();
    this.agent = agent;
  }

  listenEvents() {
    bus.on('chat:text', ({ userNick, content }) => {
      logger.verbose('to send text msg%s:%s', userNick, content);

      sendTextByUserNick(userNick, content).catch((err) => {
        logger.error('发送文本消息失败%s:%s', userNick, err);
      });
    });

    bus.on('chat:image', ({ userNick, imgUrl: imgId }) => {
      const imgUrl = `${REACT_APP_IMAGE_BUCKET}/${imgId}`;
      logger.verbose('to send image msg: %s', imgUrl);

      sendImageByUserNick(userNick, imgUrl).catch((err) => {
        logger.error('发送图片消息失败%s:%s', userNick, err);
      });
    });
  }
}

export default KSBOT;
