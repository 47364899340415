import React, { useCallback, useEffect, useRef, Ref, useImperativeHandle } from 'react';
import { css, cx } from '@emotion/css/macro';
import { throttle, debounce } from 'lodash';

export interface DragProps {
  className?: string;
  onDragging?: () => void;
  disable?: boolean;
  children: React.ReactNode;
}
export interface DragRefObject {
  isDragging: () => boolean;
}
const styles = {
  drag: css`
    position: absolute;
    user-select: none;
    img {
      user-drag: none;
    }
  `,
};
export type Rect = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export enum Status {
  Idle,
  MouseUp,
  MouseDown,
  ReadyToMove,
  Moving,
}
const Drag = (
  { disable = false, className, children, onDragging }: DragProps,
  ref: Ref<DragRefObject>,
) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const initRectRef = useRef<Rect>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const lastPosRef = useRef({ x: 0, y: 0 });
  const statusRef = useRef<Status>(Status.Idle);
  const readyToMoveTimeoutRef = useRef(0);
  const handleMouseDown = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // 防止频繁操作带来timeout过多影响性能.
      clearTimeout(readyToMoveTimeoutRef.current);
      if (disable) {
        return;
      }
      statusRef.current = Status.MouseDown;
      const timeout = setTimeout(() => {
        // 通过延迟，校验是否在持续mousedown以过滤click操作
        if (statusRef.current === Status.MouseDown) {
          // 额外添加一个ReadyToMove的状态进行更精细的调整
          statusRef.current = Status.ReadyToMove;
        }
        // mac-chrome一次点击事件大约需要120ms去完成mousedown->mouseup整个过程的响应
        // 实际情况可能会因浏览器/系统环境/实现不同有所不同,因此先按320ms进行限制
      }, 320);
      readyToMoveTimeoutRef.current = (timeout as unknown) as number;
    },
    [disable],
  );
  useEffect(() => {
    const initRect = () => {
      const { current: drag } = dragRef;
      if (drag?.getBoundingClientRect) {
        const { top, left, width, height } = drag.getBoundingClientRect();
        lastPosRef.current.x = left;
        lastPosRef.current.y = top;
        initRectRef.current.top = top;
        initRectRef.current.left = left;
        initRectRef.current.width = width;
        initRectRef.current.height = height;
      }
    };
    initRect();
    const onResize = debounce(initRect, 300);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  useEffect(() => {
    const handleMouseUp = () => {
      if (statusRef.current !== Status.Moving) {
        // 当鼠标松开时，若未产生偏移，可以认为该拖动未发生，处于空闲状态
        statusRef.current = Status.Idle;
        return;
      }
      statusRef.current = Status.MouseUp;
      setTimeout(() => {
        statusRef.current = Status.Idle;
        // 延迟重置可以帮助mouseup时子元素中的一些点击事件被同时触发的时候可以正确识别Drag组件的状态
      }, 80);
    };
    const handleMouseMoveThrottle = throttle((evt: MouseEvent) => {
      if (statusRef.current !== Status.ReadyToMove && statusRef.current !== Status.Moving) {
        return;
      }
      const {
        current: { top: initY, left: initX, width: initWidth, height: initHeight },
      } = initRectRef;
      const {
        current: { x: lastX, y: lastY },
      } = lastPosRef;
      const { current: drag } = dragRef;
      const { clientX, clientY } = evt;
      if (clientX === null || clientY === null) {
        return;
      }
      const radiusW = initWidth / 2;
      const radiusH = initHeight / 2;
      let left = clientX - initX - radiusW;
      let top = clientY - initY - radiusH;
      const { innerWidth, innerHeight } = window;
      if (left < -initX) {
        left = -initX;
      } else if (initX + left + initWidth > innerWidth) {
        left = innerWidth - (initX + initWidth);
      }
      if (top < -initY) {
        top = -initY;
      } else if (initY + top + initHeight > innerHeight) {
        top = innerHeight - (initY + initHeight);
      }
      // 处理点击时微小的x/y偏移导致错误判断dragging状态
      // 如果x/y轴上任意偏移量绝对值大于1px，认为已经进入移动状态
      if (Math.abs(left - lastX) > 1 || Math.abs(top - lastY) > 1) {
        statusRef.current = Status.Moving;
      }
      const transform = `translate(${left}px, ${top}px)`;
      if (drag?.style) {
        drag!.style.transform = transform;
        lastPosRef.current.x = left;
        lastPosRef.current.y = top;
      }
      if (onDragging) {
        onDragging();
      }
    }, 20);
    document.addEventListener('mousemove', handleMouseMoveThrottle);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMoveThrottle);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onDragging]);
  useEffect(() => {
    if (disable) {
      statusRef.current = Status.Idle;
    }
  }, [disable]);
  useImperativeHandle(
    ref,
    (): DragRefObject => {
      return {
        isDragging: () => {
          if (disable) {
            return false;
          }
          return statusRef.current === Status.Moving;
        },
      };
    },
    [disable],
  );
  return (
    <div className={cx(styles.drag, className)} ref={dragRef} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
};

export default React.forwardRef<DragRefObject, DragProps>(Drag);
