import { useCallback, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { NotificationItem } from '../../api/messageApi';

const noticeMessageState = atom<NotificationItem[]>({
  key: 'notice_message_state',
  default: [],
});

export default () => {
  const [noticeMessage, setNoticeMessage] = useRecoilState(noticeMessageState);
  const append = useCallback(
    (item: NotificationItem | NotificationItem[]) => {
      if (!item) {
        return;
      }
      /* eslint-disable camelcase */
      let local_noticeMessage: any[] = [];
      const localNoticeMessage = localStorage.getItem('NoticeMessage');
      if (localNoticeMessage) {
        local_noticeMessage = local_noticeMessage.concat(JSON.parse(localNoticeMessage));
      }
      setNoticeMessage(noticeMessage.concat(item).concat(local_noticeMessage).slice(0));
    },
    [noticeMessage, setNoticeMessage],
  );
  const list = useMemo(() => {
    return noticeMessage.map((msg, idx) => {
      return {
        ...msg,
      };
    });
  }, [noticeMessage]);
  const clear = useCallback(() => {
    setNoticeMessage([]);
  }, [setNoticeMessage]);
  const total = useMemo(() => noticeMessage.length || 0, [noticeMessage]);
  return {
    set: setNoticeMessage,
    append,
    clear,
    list,
    total,
  };
};
