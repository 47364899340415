import wait from 'utils/wait';

let isDocumentReady = false;

/**
 * 等待文档准备好
 */
function documentReady() {
  return new Promise<void>((resolve) => {
    if (isDocumentReady) {
      resolve();

      return;
    }

    if (document.readyState !== 'loading') {
      wait().then(() => {
        isDocumentReady = true;

        resolve();
      });

      return;
    }

    function completed() {
      document.removeEventListener('DOMContentLoaded', completed);
      window.removeEventListener('load', completed);

      isDocumentReady = true;

      resolve();
    }

    document.addEventListener('DOMContentLoaded', completed);
    document.addEventListener('load', completed);
  });
}

export default documentReady;
