import { ksClient } from './index';

export enum NotificationType {
  Mark = 1,
  Notice = 2,
}
type Response<T> = {
  data: T;
};
export type NotificationItem = {
  id: string;
  /* eslint-disable camelcase */
  msg_type: NotificationType;
  assistant_nick: string;
  buyer_nick: string;
  plain_text: string;
  update_time: number;
  /* eslint-enable camelcase */
};
export const fetchNoticeMessage = async (assistantName: string, isMainAccount?: boolean) => {
  // fallback：后端无法获得客服子账号的数字id，只能通过nickname进行匹配
  return ksClient.get<
    Response<{
      count: number;
      /* eslint-disable camelcase */
      notice_reminds: NotificationItem[];
      /* eslint-enable camelcase */
    }>
  >('/ecp-notice/v2/list/notice_remind', {
    params: {
      assistant_nick: assistantName,
      is_main: isMainAccount ? 1 : 0,
    },
  });
};
export const deleteNoticeMessageById = (id: NotificationItem['id']) => {
  return ksClient.delete('/ecp-notice/v2/list/notice_remind', { params: { id } });
};
export const clearAllNoticeMessageByAssistantNick = (
  assistantNick: string,
  isMainAccount?: boolean,
) => {
  const params: { [x: string]: any } = {
    assistant_nick: assistantNick,
  };
  if (isMainAccount !== undefined) {
    params.is_main = isMainAccount ? 1 : 0;
  }
  return ksClient.delete('/ecp-notice/v2/list/notice_remind', {
    params,
  });
};
export type BuyerTag = {
  /* eslint-disable camelcase */
  tag_type: NotificationType;
  buyer_sentiment_tag: string;
  /* eslint-enable camelcase */
  count: number;
};

export const fetchBuyerTag = async (assistantName: string, buyerNick: string) => {
  // fallback：后端无法获得客服子账号的数字id，只能通过nickname进行匹配
  return ksClient.get<Response<BuyerTag[]>>('/ecp-notice/v2/buyer_sentiment_tag', {
    params: {
      assistant_nick: assistantName,
      buyer_nick: buyerNick,
    },
  });
};
