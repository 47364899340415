import { Emitter } from '@leyan/emitter';
import logger from '../logger';
import bus from '../bus';
import Rpc, { ServerMesssage, Assistant } from './WebSocketJsonRPC';
import Convertor from './Convertor';
import { CommandType } from './commands';

interface AgentProps {
  endpoint: string;
  shopID: number;
  assistant: Assistant;
}

export default class Agent {
  endpoint: string;

  shopID: number;

  assistant: Assistant;

  emitter: any;

  ws: Rpc;

  convertor: Convertor;

  constructor({ endpoint, shopID, assistant }: AgentProps) {
    this.endpoint = endpoint;
    this.shopID = shopID;
    this.assistant = assistant;
    this.convertor = new Convertor();

    const emitter = new Emitter();
    emitter.on('request', (_id, fn, args) => {
      logger.warn('request method :', fn);
      logger.warn('request onPrompt: ', args);
      switch (fn) {
        case 'Agent.SSO':
          this.disconnect();
          break;
        case 'Agent.PingPong':
          this.ws.pong();
          break;
        case 'Dialogue.AnswerReply':
          this.ws.sendResponse(_id, {});

          this.onPrompt(args);
          break;
        case 'Item.ItemSync':
          break;
        case 'Trade.TradeSync':
          break;
        default:
          logger.warn('unknown method: %s', fn);
      }
    });
    this.emitter = emitter;
    this.ws = new Rpc({
      app: 'feige',
      emitter,
      shopID,
      assistant,
      endpoint,
    });
  }

  disconnect() {
    this.ws.offline = true;
    this.ws.stopBabysitting();
    this.ws.close();
  }

  connect() {
    this.ws.offline = false;
    return this.ws.connect();
  }

  toString() {
    const { assistant, shopID } = this;
    return `<Agent ${assistant} ${shopID}>`;
  }

  onPrompt(args: ServerMesssage['args']) {
    const { userNick, commands, queueName } = this.convertor.convert(args);
    logger.info('Prompt [%s x %s x %d]', userNick, queueName, commands.length);

    for (const command of commands) {
      switch (command.type) {
        case CommandType.sendText:
          bus.emit('chat:text', {
            userNick,
            content: command.text,
          });
          break;
        case CommandType.sendImage:
          bus.emit('chat:image', {
            userNick,
            imgUrl: command.imageId,
          });
          break;
        default:
          logger.warn('unknown message type %s', command.type);
          break;
      }
    }
  }

  syncMessage<T>(raw: T) {
    logger.info('Dialogue.SyncMessagesV2 raw:', raw);
    this.ws.sendRequest('Dialogue.SyncMessagesV2', raw);
  }
}
