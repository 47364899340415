import { queryRootVM, queryVM, VM } from '@leyan/vm-helper';

import documentReady from 'utils/documentReady';
import { loadImage, getImageSize, ImageSize } from 'utils/image';
import logger from './logger';

/**
 * 附加图片尺寸信息的文件
 */
export interface SizedFile extends File, ImageSize {}

/**
 * 买家信息
 */
export interface User {
  /**
   * 买家 ID
   */
  userId: number;
  /**
   * 买家昵称
   */
  userNick: string;
  /**
   * 买家头像地址
   */
  userImage: string;
}

/**
 * 接收者
 */
export interface Receiver extends User {
  from: string;
}

/**
 * 对话信息
 */
export interface Session extends User {
  from: string;
  /**
   * 最后一句消息内容
   */
  lastMessage: string;
  /**
   * 最后一句消息时间
   */
  time: Date;
  /**
   * 对话视图类型
   */
  sessionViewType: /** 10: 人工回复 */ 10 | /** 11: 智能回复 */ 11 | /** 12: 未回复 */ 12;
  unread: number;
}

/**
 * 目录的对话信息
 */
export interface FolderSession extends Session {
  /**
   * 目录
   */
  folder:
    | /** 2: 请在5分钟内回复 */ '2'
    | /** 3: 已回复 */ '3'
    | /** 4: 已自动回复  */ '4'
    | /** 5: 已超时 */ '5';
  /**
   * 本地亮灯状态
   */
  __localLightState?: boolean;
}

/**
 * 登录客服信息
 */
export interface Mine extends User {
  /**
   * 角色
   */
  role: number;
  /**
   * 卖家 ID
   */
  sellerId: number;
  /**
   * 卖家昵称
   */
  sellerName?: string;
}

/**
 * IM 模型
 */
export interface IMModel {
  /**
   * 等待登录
   */
  waitLogin: Promise<void>;
  /**
   * 登录客服信息
   */
  mine: Mine;
  /**
   * 当前买家信息
   */
  receiver: Receiver;
  /**
   * 所有对话信息
   */
  allSessions: Session[];
  /**
   * 发送文本消息
   * @param message 消息内容
   * @param extra 额外信息
   * @param receiver 接收用户
   */
  sendText(
    message: string,
    extra: Record<string, any> | null,
    receiver: {
      /**
       * 接收用户 ID
       */
      receiverId: number;
    },
  ): Promise<void>;
  /**
   * 发送图片消息
   * @param file 图片文件
   * @param targetId 目标用户 ID
   */
  sendImage(file: SizedFile, targetId: number): Promise<void>;
}

/**
 * IM 对话目录模型
 */
export interface IMSessionFolderModel {
  /**
   * 所有对话信息
   */
  allSessions: FolderSession[];
}

/**
 * 用户信息
 */
export interface UserInfo extends Pick<IMModel, 'mine' | 'receiver'> {}

/**
 * IM 服务
 */
export interface IMService {
  /**
   * 获取 IMSdk
   */
  getImSdk(): unknown;
  /**
   * 发送文本消息
   * @param message 消息内容
   * @param targetId 目标用户 ID
   * @param userInfo 用户信息
   */
  sendText(message: string, targetId: number, userInfo: UserInfo): Promise<void>;
  /**
   * 发送图片消息
   * @param file 图片文件
   * @param targetId 目标用户 ID
   * @param userInfo 用户信息
   */
  sendImage(file: SizedFile, targetId: number, userInfo: UserInfo): Promise<void>;
}

/**
 * IM 缓存
 */
interface IMCache {
  /**
   * IM 模型 `vue` 实例
   */
  modelVM?: VM;
  /**
   * IM 模型
   */
  model?: IMModel;
  /**
   * IM 对话目录模型 `vue` 实例
   */
  sessionFolderModelVM?: VM;
  /**
   * IM 对话目录模型
   */
  sessionFolderModel?: IMSessionFolderModel;
  /**
   * IM 服务 `vue` 实例
   */
  imServiceVM?: VM;
  /**
   * IM 服务
   */
  imService?: IMService;
  /**
   * 用户映射表
   */
  usersMap: Map<string, Receiver>;
  /**
   * 是否已经缓存
   */
  _cached: boolean;
  /**
   * 是否准备好可以被使用
   */
  _ready: boolean;
}

export interface IM
  extends Required<Omit<IMCache, 'imServiceVM' | 'imService'>>,
    Pick<IMCache, 'imServiceVM' | 'imService'> {}

/**
 * IM 缓存实例
 */
const imCache: IMCache = {
  usersMap: new Map(),
  _ready: false,
  _cached: false,
};

/**
 * 判断目标对象是否是 IM 模型
 * @param target 目标对象
 */
function isIMModel(target: any): target is IMModel {
  const candidate = target as IMModel;

  return Boolean(
    candidate &&
      candidate.mine &&
      typeof candidate.mine.role === 'number' &&
      typeof candidate.mine.sellerId === 'number' &&
      (candidate.mine.sellerName === undefined || typeof candidate.mine.sellerName === 'string') &&
      typeof candidate.mine.userId === 'number' &&
      typeof candidate.mine.userNick === 'string' &&
      typeof candidate.mine.userImage === 'string' &&
      Array.isArray(candidate.allSessions) &&
      candidate.waitLogin &&
      typeof candidate.sendText === 'function' &&
      typeof candidate.sendImage === 'function',
  );
}

/**
 * 判断目标对象是否是 IM 对话目录模型
 * @param target 目标对象
 */
function isIMSessionFolderModel(target: any): target is IMSessionFolderModel {
  const candidate = target as IMSessionFolderModel;

  return Boolean(candidate && Array.isArray(candidate.allSessions));
}

/**
 * 判定目标对象是否是 IM 服务
 * @param target 目标对象
 */
function isIMService(target: any): target is IMService {
  const candidate = target as IMService;
  logger.debug('candidate:', candidate);

  return Boolean(
    candidate &&
      typeof candidate.getImSdk === 'function' &&
      typeof candidate.sendText === 'function' &&
      typeof candidate.sendImage === 'function',
  );
}

/**
 * 从 IM 模型 `Vue` 实例中获取 IM 模型
 * @param vm `Vue` 实例
 */
function getIMModelFromVM(vm: VM) {
  if (isIMModel((vm as any).model)) {
    logger.debug('IMModel:', (vm as any).model);

    return (vm as any).model as IMModel;
  }

  return null;
}

/**
 * 从 IM 对话目录模型 `Vue` 实例中获取 IM 对话目录模型
 * @param vm `Vue` 实例
 */
function getIMSessionFolderModelFromVM(vm: VM) {
  if (isIMSessionFolderModel((vm as any).sessionFolderModel)) {
    logger.debug('IMSessionFolderModel:', (vm as any).sessionFolderModel);

    return (vm as any).sessionFolderModel as IMSessionFolderModel;
  }

  if (isIMSessionFolderModel((vm as any).sessionViewModel)) {
    logger.debug('IMSessionViewModel:', (vm as any).sessionViewModel);
    return (vm as any).sessionViewModel as IMSessionFolderModel;
  }

  return null;
}

/**
 * 从 IM 服务 `Vue` 实例中获取 IM 服务
 * @param vm `Vue` 实例
 */
function getIMServiceFromVM(vm: VM) {
  if (isIMService((vm as any)?.sendBoxViewModel?.imservice)) {
    return (vm as any).sendBoxViewModel.imservice as IMService;
  }

  return null;
}

/**
 * 获取 IM 缓存对象
 */
export async function getIM() {
  // 等待文档准备好再从从文档中获取 IM 缓存对象
  await documentReady();
  logger.debug('IM found: %o', imCache);
  if (!imCache._cached || (imCache?.modelVM as any)._isDestroyed) {
    const rootVM = queryRootVM();

    if (!rootVM) {
      throw new Error('Get IM failed, can not found root vm.');
    }

    const modelVM = queryVM((vm) => {
      return Boolean(getIMModelFromVM(vm));
    }, rootVM);

    if (!modelVM) {
      throw new Error('Get IM failed, can not found model vm.');
    }

    const sessionFolderModelVM = queryVM((vm) => {
      return Boolean(getIMSessionFolderModelFromVM(vm));
    }, rootVM);

    if (!sessionFolderModelVM) {
      throw new Error('Get IM failed, can not found session folder model vm.');
    }

    imCache.modelVM = modelVM;
    imCache.model = getIMModelFromVM(modelVM)!;
    imCache.sessionFolderModelVM = sessionFolderModelVM;
    imCache.sessionFolderModel = getIMSessionFolderModelFromVM(sessionFolderModelVM)!;

    const imServiceVM = queryVM((vm) => {
      return Boolean(getIMServiceFromVM(vm));
    }, rootVM);

    if (imServiceVM) {
      imCache.imServiceVM = imServiceVM;
      imCache.imService = getIMServiceFromVM(imServiceVM)!;
    } else {
      logger.warn('IM Service not found');
    }

    imCache._cached = true;

    logger.debug('IM found: %o', imCache);
  }

  return imCache as IM;
}

/**
 * 等待 IM 可用
 */
export async function whenReady() {
  const im = await getIM();

  if (!im._ready) {
    await im.model.waitLogin;

    im._ready = true;
  }

  return im;
}

/**
 * 使用 IM 缓存上下文
 * @param callback 回调函数
 */
export async function withIM<T>(callback: (im: IM) => Promise<T> | T) {
  const im = await whenReady();
  logger.info('IM info: %o', im);
  return callback(im);
}

/**
 * 获取客服信息
 */
export function getAssistant() {
  return withIM((im) => {
    return im.model.mine;
  });
}

/**
 * 获取用户信息
 * @param userNick 用户昵称
 */
export function getUser(userNick: string) {
  return withIM((im) => {
    const user = im.usersMap.get(userNick);

    if (user) {
      return user;
    }

    for (const session of im.model.allSessions) {
      const user: Receiver = {
        from: session.from,
        userId: session.userId,
        userNick: session.userNick,
        userImage: session.userImage,
      };

      if (!im.usersMap.has(user.userNick)) {
        im.usersMap.set(session.userNick, user);
      }

      if (user.userNick === userNick) {
        return user;
      }
    }

    return null;
  });
}

/**
 * 使用用户上下文
 * @param userNick 用户昵称
 * @param callback 回调函数
 */
export function withUser<T>(
  userNick: string,
  callback: (user: Receiver, im: IM) => Promise<T> | T,
) {
  return withIM(async (im) => {
    const user = await getUser(userNick);

    if (user) {
      return callback(user, im);
    }

    throw new Error(`Find user by "${userNick}" failed.`);
  });
}

/**
 * 获取所有对话信息
 */
export function getAllSessions() {
  return withIM((im) => {
    return im.sessionFolderModel.allSessions;
  });
}

/**
 * 发送文本消息
 * @param targetId 目标用户 ID
 * @param message 消息内容
 * @param userInfo 用户信息
 * @param extra 额外信息
 */
export function sendText(
  targetId: number,
  message: string,
  userInfo: UserInfo,
  extra: Record<string, any> | null = null,
) {
  return withIM((im) => {
    if (im.imService) {
      return im.imService.sendText(message, targetId, userInfo);
    }

    return im.model.sendText(message, extra, { receiverId: targetId });
  });
}

/**
 * 根据用户昵称发送文本消息
 * @param userNick 用户昵称
 * @param message 消息内容
 * @param extra 额外信息
 */
export function sendTextByUserNick(
  userNick: string,
  message: string,
  extra: Record<string, any> | null = null,
) {
  return withUser(userNick, (user, im) => {
    return sendText(user.userId, message, { mine: im.model.mine, receiver: user }, extra);
  });
}

/**
 * 发送图片消息
 * @param targetId 目标用户 ID
 * @param url 图片地址
 * @param userInfo 用户信息
 */
export function sendImage(targetId: number, url: string, userInfo: UserInfo) {
  return withIM(async (im) => {
    const blob = await loadImage(url);
    const { width, height } = await getImageSize(url);
    const file = new File([blob], encodeURIComponent(url), { type: blob.type }) as SizedFile;

    file.width = width;
    file.height = height;

    if (im.imService) {
      return im.imService.sendImage(file, targetId, userInfo);
    }

    return im.model.sendImage(file, targetId);
  });
}

/**
 * 根据用户昵称发送图片消息
 * @param userNick 用户昵称
 * @param url 图片地址
 */
export function sendImageByUserNick(userNick: string, url: string) {
  return withUser(userNick, (user, im) => {
    return sendImage(user.userId, url, { mine: im.model.mine, receiver: user });
  });
}

/**
 * 改变亮灯状态
 * @param userId 用户 ID
 * @param state 是否亮灯
 */
export async function light(userId: number, state: boolean) {
  const sessionList = await getAllSessions();
  // eslint-disable-next-line no-console
  console.log('sessionList:', sessionList);
  for (const session of sessionList) {
    if (session.userId === userId) {
      // 12: 未回复 11: 智能回复
      // session.sessionViewType = state ? 12 : 11;
      session.sessionViewType = 12;
      session.__localLightState = state;

      return;
    }
  }
}

/**
 * 根据用户昵称改变亮灯状态
 * @param userNick 用户昵称
 * @param state 是否亮灯
 */
export function lightByUserNick(userNick: string, state: boolean) {
  return withUser(userNick, (user) => {
    return light(user.userId, state);
  });
}
