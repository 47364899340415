/* eslint-disable max-classes-per-file */
// import logger from '../utils/logger';
export const CommandType = {
  sendText: Symbol.for('CommandSendText'),
  sendImage: Symbol.for('CommandSendImage'),
};
export class CommandSendText {
  text: string;

  mode: string;

  type = CommandType.sendText;

  constructor(text: string, mode: string) {
    this.text = text;
    this.mode = mode;
  }

  toString() {
    const { type, mode, text } = this.toJSON();
    return `<${type}: ${mode} ${text}>`;
  }

  toJSON() {
    return {
      type: this.type.toString(),
      mode: this.mode,
      text: this.text,
    };
  }
}

export class CommandSendImage {
  imageId: string;

  mode: string;

  type = CommandType.sendImage;

  constructor(imageId: string, mode: string) {
    this.imageId = imageId;
    this.mode = mode;
  }

  toString() {
    const { type, imageId } = this.toJSON();
    return `<${type}: ${imageId}>`;
  }

  toJSON() {
    return {
      type: this.type.toString(),
      imageId: this.imageId,
    };
  }
}
