/**
 * 发送文本消息命令
 */
export interface TextCommand {
  /**
   * 命令类型
   */
  type: 'text';
  /**
   * 文本消息
   */
  text: string;
}

/**
 * 发送图片消息命令
 */
export interface ImageCommand {
  /**
   * 命令类型
   */
  type: 'image';
  /**
   * 图片地址
   */
  url: string;
}

/**
 * 命令
 */
export type Command = TextCommand | ImageCommand;

/**
 * 从话术片段创建命令
 * @param segments 话术片段
 */
export function createCommandsFromSegments(segments: any) {
  const commands: Command[] = [];
  let currentText = '';

  function tryAddTextCommand() {
    if (currentText.length > 0) {
      commands.push({
        type: 'text',
        text: currentText,
      });

      currentText = '';
    }
  }

  for (const segment of segments) {
    switch (segment.type) {
      case 'text': {
        currentText += segment.text;

        break;
      }
      case 'image': {
        tryAddTextCommand();

        commands.push({
          type: 'image',
          url: segment.src,
        });

        break;
      }
      case 'end_of_sentence': {
        tryAddTextCommand();

        break;
      }
      default: {
        break;
      }
    }
  }

  tryAddTextCommand();

  return commands;
}
