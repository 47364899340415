import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css, cx } from '@emotion/css/macro';
import { debounce } from 'lodash';
import { Row, Col, Button, Popover, Badge, Popconfirm } from 'antd';
import { useClickAway } from 'ahooks';
import Drag, { DragRefObject } from 'components/Drag';
import { EventTypes, report } from 'api/fredApi';
import logoPNG from './logo.png';
import { ReactComponent as ClearSvg } from './clear.svg';
import Empty from '../../components/Empty';
import MessageItem from '../../components/MessageItem';
import useChatListState, { ChatStatus } from '../../hooks/biz/useChatListState';
import useNoticeMessagState from '../../hooks/biz/useNoticeMessagState';
import useFloatingLayerState from './useFloatingLayerState';

const APP_NAME = process.env.REACT_APP_NAME;
const styles = {
  container: css`
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
  `,
  drag: css`
    position: fixed;
    right: 45px;
    bottom: 40px;
    padding: 10px 10px 0 0;
    z-index: 11;
  `,
  floatingBtn: css`
    width: 50px;
    height: 50px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    > img {
      width: 100%;
      height: auto;
    }
  `,
  overlay: css`
    min-width: 350px;
    max-width: 20vw;
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  `,
  messageItem: css`
    padding-left: 16px;
    padding-right: 16px;
    &.canJump {
      cursor: pointer;
    }
  `,
  clearBtn: css`
    color: #515359 !important;
    > svg,
    > span {
      vertical-align: middle;
    }
    > svg {
      margin-right: 4px;
    }
  `,
  empty: css`
    padding: 16px;
  `,
};
const FloatingLayer: React.FC = () => {
  const { clearNoticeMessage } = useFloatingLayerState();
  const { sync: syncChatList, chatList, jumpToChatByBuyerNick } = useChatListState();
  const floatRef = useRef<HTMLAnchorElement>(null);
  const { list } = useNoticeMessagState();
  const dragRef = useRef<DragRefObject | null>(null);
  const [visible, setVisible] = useState(false);
  const handleClick = useCallback(() => {
    if (dragRef?.current?.isDragging()) {
      setVisible(false);
    } else {
      setVisible((v) => !v);
    }
  }, [dragRef]);
  useEffect(() => {
    const debouncedObFn = debounce(() => {
      syncChatList();
    }, 500);
    const observer = new MutationObserver(debouncedObFn);
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(document, config);
    return () => {
      observer.disconnect();
    };
  }, [syncChatList]);
  useClickAway(() => {
    setVisible(false);
  }, floatRef);
  useEffect(() => {
    if (visible) {
      report({
        event_type: EventTypes.KS_EXTENSION_OPEN_MESSAGE_LIST,
      });
    }
  }, [visible]);
  const popContent = useMemo(() => {
    return list.length < 1 ? (
      <Empty className={styles.empty} />
    ) : (
      <div className={styles.container}>
        {list.map((item) => {
          const chat = chatList.find((chat) => {
            return item.buyer_nick === chat.buyerNick;
          });
          const canJump = !!chat?.uid;
          // 如果没有匹配的聊天记录，认为已回复
          // eslint-disable-next-line no-console
          console.log('chat replied:', chat);
          const replied = chat ? chat.status === ChatStatus.replied : true;
          return (
            <MessageItem
              onClick={() => {
                jumpToChatByBuyerNick(chat?.buyerNick);
              }}
              className={cx(styles.messageItem, canJump && 'canJump')}
              key={`notice_message_item${item.id}`}
              dataSource={item}
              replied={replied}
            />
          );
        })}
      </div>
    );
  }, [chatList, jumpToChatByBuyerNick, list]);
  const popTitle = useMemo(
    () => (
      <Row align="middle" justify="space-between">
        <Col>{APP_NAME}</Col>
        <Col>
          <Popconfirm
            disabled={list.length < 1}
            title="确认清空？"
            onConfirm={() => {
              clearNoticeMessage();
              report({
                event_type: EventTypes.KS_EXTENSION_CLEAR_MESSAGE_LIST,
              });
            }}
            okText="清空"
            cancelText="取消"
          >
            <Button disabled={list.length < 1} type="text" className={styles.clearBtn}>
              <ClearSvg />
              <span>清空消息</span>
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    ),
    [clearNoticeMessage, list.length],
  );
  return (
    <Drag
      disable={visible}
      className={styles.drag}
      ref={dragRef}
      onDragging={() => setVisible(false)}
    >
      <Popover
        visible={visible}
        trigger="click"
        arrowPointAtCenter
        overlayClassName={styles.overlay}
        content={popContent}
        title={popTitle}
        getPopupContainer={() => floatRef.current || document.body}
      >
        <Badge count={list.length}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a draggable="false" ref={floatRef} className={styles.floatingBtn} onClick={handleClick}>
            <img draggable="false" alt={APP_NAME} src={logoPNG} />
          </a>
        </Badge>
      </Popover>
    </Drag>
  );
};

export default FloatingLayer;
