import React, { useEffect, useState, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { css } from '@emotion/css/macro';
import { debounce } from 'lodash';
import { Popover } from 'antd';
import logger from 'services/logger';
import FeedbackTag from '../../components/FeedbackTag';
import useKsState from '../../hooks/biz/useKsState';
import { BuyerTag, fetchBuyerTag, NotificationType } from '../../api/messageApi';
import useAutoCollasped from '../../hooks/useAutoCollasped';

const styles = {
  container: css`
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    padding: 8px;
    background: rgba(253, 246, 236, 0.8);
    box-sizing: border-box;
    overflow-x: auto;
  `,
  countText: css`
    margin-left: 4px;
  `,
};
const getBarMountPoint = () => {
  return document.querySelector('#entry_pc > .middle-area > .middle-main > .send-comp') || null;
};

const MessageBar: React.FC = () => {
  const { currentBuyer, assistant, sync } = useKsState();
  const [, setForceUpdate] = useState(0);
  const targetDomRef = useRef<Element | null>(getBarMountPoint());
  const mountNodeRef = useRef<Element | null>(null);
  const [tags, setTags] = useState<BuyerTag[]>([]);
  const barRef = useRef<HTMLDivElement>(null);
  const visible = !!targetDomRef.current && !!mountNodeRef.current && tags.length > 0;
  const { overflow, count } = useAutoCollasped(barRef, {
    deps: [tags, visible],
    reserveWidth: 85,
  });
  const helpMount = () => {
    if (targetDomRef.current) {
      // createPortal只能将元素append进去指定的节点
      // 但是由于目标节点下有其他官方浮层需要在层叠时覆盖住这个组件，
      // 因此新建一个节点进行prepend挂载
      const mountNode = document.createElement('div');
      mountNodeRef.current = mountNode;
      targetDomRef.current.prepend(mountNode);
    }
  };
  useEffect(() => {
    helpMount();
  }, []);
  useEffect(() => {
    const debouncedObFn = debounce(() => {
      const nextTarget = getBarMountPoint();
      sync();
      if (nextTarget && nextTarget !== targetDomRef.current) {
        // createPortal只能将元素append进去指定的节点
        // 但是由于目标节点下有其他官方浮层需要在层叠时覆盖住这个组件，
        // 因此新建一个节点进行prepend挂载
        targetDomRef.current = nextTarget;
        helpMount();
        setForceUpdate((p) => p + 1);
      }
    }, 500);
    const observer = new MutationObserver(debouncedObFn);
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(document, config);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const assistantNick = assistant?.assistantNick;
  const buyerNick = currentBuyer?.nick;
  useEffect(() => {
    if (assistantNick && buyerNick) {
      fetchBuyerTag(assistantNick, buyerNick)
        .then((resp) => {
          setTags(resp.data.data);
        })
        .catch(() => {
          logger.error('获取客服标签失败');
        });
    }
  }, [assistantNick, buyerNick]);
  const content = useMemo(() => {
    const result = tags.map((tag, idx) => {
      // 这一期暂时没有“好”的情绪，下一期需要做
      const { count: tagCount } = tag;
      const type = tag.tag_type === NotificationType.Mark ? 'mark' : 'bad';
      let countText = tagCount ? `${tagCount}` : null;
      if (tagCount > 99) {
        countText = '99+';
      }
      return (
        <FeedbackTag
          // eslint-disable-next-line react/no-array-index-key
          key={`feedback_tags_${tag.tag_type}_${idx}`}
          type={type}
        >
          {tag.buyer_sentiment_tag}
          {countText ? <span className={styles.countText}>{countText}</span> : null}
        </FeedbackTag>
      );
    });
    const restCount = count ? tags.length - count : 0;
    if (overflow && restCount) {
      const show = result.splice(0, count);
      show.push(
        <Popover key="rest_popover" content={result}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <FeedbackTag type="normal">
              还有
              {restCount}个
            </FeedbackTag>
          </a>
        </Popover>,
      );
      return show;
    }
    return result;
  }, [tags, overflow, count]);
  return visible
    ? ReactDOM.createPortal(
        <div ref={barRef} className={styles.container}>
          {content}
        </div>,
        mountNodeRef.current as Element,
      )
    : null;
};
export default MessageBar;
