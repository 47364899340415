/**
 * 等待指定时间
 * @param time 等待时间 (毫秒)
 */
function wait(time?: number) {
  return new Promise<number>((reslove) => {
    const start = Date.now();

    setTimeout(() => {
      const end = Date.now();

      reslove(end - start);
    }, time);
  });
}

export default wait;
