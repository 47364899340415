import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { uniqBy } from 'lodash';
import { EventTypes, report } from 'api/fredApi';

export enum ChatStatus {
  unreply,
  replied,
}
const chatListState = atom<
  {
    uid: string;
    count: number;
    status: ChatStatus;
    buyerNick: string;
    active: boolean;
  }[]
>({
  key: 'chat_list_state',
  default: [],
});

export const triggerKSAllSessionTab = () => {
  const $tabs = document.querySelectorAll(
    '#entry_pc > .left-area > .left-main > .session-tab-wrap > .tab',
  ) as NodeListOf<HTMLDivElement>;
  if (!$tabs) {
    return;
  }
  let activeIndex: number | null = null;
  // eslint-disable-next-line no-plusplus
  for (let idx = 0; idx < $tabs.length; idx++) {
    const $tab = $tabs[idx];
    const isActive = $tab.className.split(' ').includes('active');
    if (activeIndex === null) {
      activeIndex = isActive ? idx : null;
    }
    if (($tab.querySelector('.text') as HTMLDivElement)?.innerText?.trim() === '全部') {
      if (isActive) {
        // 如果当前在“全部”的tab下，不操作
        return;
      }
      // 如果不在“全部”tab下，模拟点击，触发“全部”tab切换以获得所有的聊天信息
      $tab.click();
    }
  }
};

const useChatListState = () => {
  const [chatList, setChatList] = useRecoilState(chatListState);
  const sync = useCallback(() => {
    const $buyers = document.querySelectorAll(
      '#entry_pc > .left-area > .left-main > .session-list-comp .user-info-card',
    );
    if ($buyers && $buyers.length > 0) {
      const nextList: typeof chatList = [];
      // eslint-disable-next-line no-plusplus
      for (let idx = 0; idx < $buyers.length; idx++) {
        const $buyer = $buyers[idx];
        if ($buyer?.querySelector) {
          const $title = $buyer.querySelector('.info > .top-head > .title');
          const uid = $buyer.getAttribute('uid') || '';
          const className = $buyer.className.split(' ');
          const active = className.includes('active');
          const unreply = className.includes('unreply');
          const buyerNick = $title?.querySelector('.name')?.innerHTML || '';
          const count = $title?.querySelector('.count')?.innerHTML || 0;
          nextList.push({
            uid,
            count: Number(count),
            buyerNick,
            active,
            // status: unreply ? ChatStatus.unreply : ChatStatus.replied,
            status: unreply ? ChatStatus.replied : ChatStatus.replied,
          });
        }
      }
      const uniqList = uniqBy(nextList, 'uid');
      setChatList(uniqList);
    } else {
      setChatList([]);
    }
  }, [setChatList]);
  const jumpToChatByBuyerNick = useCallback(
    (buyerNick?: string) => {
      if (!buyerNick) {
        return;
      }
      const chat = chatList.find((c) => c.buyerNick === buyerNick);
      if (chat?.uid) {
        report({
          event_type: EventTypes.KS_EXTENSION_JUMP_TO_SESSION_BY_MESSAGE,
        });
        window.location.hash = `#ud=${chat.uid}`;
      }
    },
    [chatList],
  );
  return { sync, chatList, jumpToChatByBuyerNick };
};

export default useChatListState;
