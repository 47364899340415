/* eslint-disable camelcase */
import { nanoid } from 'nanoid';

import { Command } from 'utils/template';
import logger from 'services/logger';
import { fredClient } from './index';

export enum EventTypes {
  KS_EXTENSION_LOAD,
  KS_EXTENSION_OPEN_MESSAGE_LIST,
  KS_EXTENSION_CLEAR_MESSAGE_LIST,
  KS_EXTENSION_JUMP_TO_SESSION_BY_MESSAGE,
}

export type Data = {
  event_type: EventTypes;
  current_time?: string;
  extra_info?: {
    [x: string]: any;
  };
};

export interface Events {
  /**
   * 兜底话术开始发送
   */
  KS_EXTENSION_FALLBACK_ANSWER_SEND: {
    /**
     * 客服 ID
     */
    assistantId: number;
    /**
     * 客服昵称
     */
    assistantNick: string;
    /**
     * 买家 ID
     */
    buyerId: number;
    /**
     * 买家昵称
     */
    buyerNick: string;
    /**
     * 执行命令
     */
    commands: Command[];
  };
  /**
   * 兜底话术发送成功
   */
  KS_EXTENSION_FALLBACK_ANSWER_SEND_SUCCESS: {
    /**
     * 命令类型
     */
    commandType: Command['type'];
    /**
     * 执行命令序号
     */
    commandIndex: number;
  };
  /**
   * 兜底话术发送失败
   */
  KS_EXTENSION_FALLBACK_ANSWER_SEND_FAIL: {
    /**
     * 命令类型
     */
    commandType: Command['type'];
    /**
     * 执行命令序号
     */
    commandIndex: number;
    /**
     * 错误信息
     */
    message: string;
  };
  KS_EXTENSION_FALLBACK_ANSWER_SEND_FINISH: {
    /**
     * 进日执行次数
     */
    executeCount: number;
    /**
     * 本次执行命令数
     */
    commandCount: number;
    /**
     * 本次执行命令成功数
     */
    commandSuccessCount: number;
  };
}

export const report = (_data: Data) => {
  const data = {
    current_time: `${Date.now()}`,
    extra_info: {},
    ..._data,
    event_type: EventTypes[_data.event_type],
  };
  return fredClient.post('/report', data).catch(() => {});
};

export function event<TEventName extends keyof Events>(
  name: TEventName,
  payload: Events[TEventName],
  id: string = nanoid(),
) {
  const ts = Date.now();

  fredClient
    .post('/report', {
      event_type: name,
      current_time: String(ts),
      extra_info: {
        id,
        ...payload,
      },
    })
    .then(() => {
      logger.verbose('上报事件 "%s@%s" %o', name, id, payload);
    })
    .catch((error) => {
      logger.error('上报事件 "%s@%s" 失败: %s', name, id, error);
    });

  return {
    id,
    ts,
    event<TEventName extends keyof Events>(name: TEventName, payload: Events[TEventName]) {
      return event(name, payload, id);
    },
  };
}
