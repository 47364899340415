import React, { HTMLAttributes } from 'react';
import { css, cx } from '@emotion/css/macro';
import { Row, Col, Space } from 'antd';
import { ReactComponent as AssistantSvg } from './assistant.svg';
import { ReactComponent as BellSvg } from './bell.svg';
import { NotificationItem, NotificationType } from '../../api/messageApi';

interface MessageItemProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  dataSource: NotificationItem;
  replied?: boolean;
}
const styles = {
  box: css`
    padding: 12px 0;
    border-bottom: 1px solid #d8d8d8;
    &.unreplied {
      background: #fdf6ec;
    }
  `,
  userBox: css`
    color: #303133;
    font-weight: 500;
    font-size: 14px;
    > span {
      vertical-align: middle;
    }
  `,
  markFlag: css`
    padding: 0 8px;
    line-height: 24px;
    text-align: center;
    color: #f19f0c;
    border: 1px solid currentColor;
    background: #fdf6ec;
    font-size: 12px;
  `,
  assistantBox: css`
    color: #3f9eff;
    > span,
    > svg {
      vertical-align: middle;
    }
    > svg {
      margin-right: 4px;
    }
  `,
  content: css`
    color: #666666;
    font-size: 14px;
    margin: 4x 0;
    .unreplied & {
      color: #f19f0c;
    }
  `,
  unreplyDot: css`
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #f19f0c;
    border-radius: 50%;
  `,
  bell: css`
    height: 22px;
  `,
};
const MessageItem: React.FC<MessageItemProps> = ({
  className,
  dataSource,
  replied = false,
  ...otherProps
}) => {
  const isMarked = dataSource.msg_type === NotificationType.Mark;
  let isReplied = replied;
  isReplied = dataSource.plain_text !== '兜底消息';
  return (
    <div className={cx(styles.box, !isReplied && 'unreplied', className)} {...otherProps}>
      <Row justify="space-between">
        <Col className={styles.userBox}>
          <Space>
            <span>{dataSource.buyer_nick}</span>
          </Space>
        </Col>
        <Col className={styles.assistantBox}>
          <AssistantSvg />
          <span>{dataSource.assistant_nick}</span>
        </Col>
      </Row>
      <Row className={styles.content} justify="space-between" align="middle">
        <Col>
          {isMarked
            ? `该用户被标记为“${dataSource.plain_text || ''}”`
            : `发送“${dataSource.plain_text}”`}
        </Col>
        {replied && !isReplied && (
          <Col>
            <span className={styles.unreplyDot} />
          </Col>
        )}
        {replied && isReplied && (
          <Col className={styles.bell}>
            <BellSvg />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default React.memo(MessageItem);
