import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { ReactComponent as MarkSvg } from './mark.svg';

interface FeedbackProps {
  className?: string;
  children: React.ReactNode;
  type?: 'good' | 'bad' | 'normal' | 'mark';
}
const styles = {
  tag: css`
    display: inline-block;
    margin-right: 8px;
    line-height: 25px;
    padding: 0 12px;
    color: #fff;
    font-size: 14px;
    border-radius: 14px 14px 14px 2px;
    opacity: 0.8;
    transition: opacity 0.1s;

    &:hover {
      opacity: 1;
    }
  `,
  normal: css`
    color: #84868c;
    background: #e1e4eb;
  `,
  good: css`
    background: #3f9eff;
  `,
  bad: css`
    background: #f56e6e;
  `,
  mark: css`
    position: relative;
    padding-left: 35px;
    margin-left: 5px;
    background: #ff8127;
    border-radius: 4px;
  `,
  markIcon: css`
    position: absolute;
    top: -7px;
    left: -5px;
  `,
};
const FeedbackTag: React.FC<FeedbackProps> = ({ className, children, type = 'normal' }) => {
  const markIcon = type === 'mark' && <MarkSvg className={styles.markIcon} />;
  return (
    <div className={cx(className, styles.tag, styles[type])}>
      {markIcon}
      {children}
    </div>
  );
};

export default React.memo(FeedbackTag);
