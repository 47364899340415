import logger from 'services/logger';
import userSendCache from 'services/userSendCache';
import {
  getAllSessions,
  sendText,
  sendImage,
  light,
  FolderSession,
  getAssistant,
  Mine,
} from 'services/im';
import Bot from 'services';
import { event } from 'api/fredApi';
import { GuaranteeConfig, GuaranteeTemplate } from 'api/storeHome';
import wait from 'utils/wait';
import { createCommandsFromSegments } from 'utils/template';

/**
 * 兜底话术同步配置
 */
export interface SyncConfig extends GuaranteeConfig {
  /**
   * 话术模板
   */
  template: GuaranteeTemplate;
}

/**
 * 兜底话术同步函数
 */
export interface SyncConfigFunction {
  (): Promise<SyncConfig>;
}

/**
 * IM 逻辑配置
 */
export interface SetupIMConfig {
  /**
   * 兜底话术定时同步时间 (毫秒)
   */
  syncIntervalTime?: number;
  /**
   * 兜底话术定时执行时间 (毫秒)
   */
  executeIntervalTime?: number;
  /**
   * 最晚可回复时间 (毫秒)
   */
  replyMaxTime?: number;
}

/**
 * 启动 IM 逻辑
 * @param syncConfig 兜底话术同步函数
 * @param setupConfig IM 逻辑配置
 */
function setupIM(syncConfig: SyncConfigFunction, setupConfig: SetupIMConfig = {}) {
  const {
    syncIntervalTime = 5 * 60 * 1000,
    executeIntervalTime = 10 * 1000,
    replyMaxTime = 5 * 60 * 1000,
  } = setupConfig;

  let syncing = false;
  let config: SyncConfig | null = null;
  let configPromise: Promise<SyncConfig>;
  const executingSet = new Set<number>();

  logger.verbose('setupIM 启动 IM 业务逻辑');

  userSendCache.prune();

  /**
   * 同步兜底话术配置
   */
  async function sync() {
    if (!syncing) {
      logger.verbose('同步兜底话术配置');

      syncing = true;

      try {
        configPromise = syncConfig();

        config = await configPromise;

        logger.verbose('兜底话术配置同步成功: %o', config);
      } catch (error) {
        logger.error('兜底话术同步失败: %s', error);
      }

      syncing = false;
    } else {
      logger.warn('兜底话术正在同步中，跳过本次同步');
    }
  }

  /**
   * 执行兜底回复逻辑
   */
  async function execute() {
    let currentConfig = config;

    if (!currentConfig) {
      try {
        logger.verbose('兜底话术配置加载中，等待配置加载完成');
        currentConfig = await configPromise;
      } catch (error) {
        logger.error('兜底话术配置加载失败: %s', error);
      }
    }

    if (!currentConfig) {
      logger.warn('兜底话术配置加载失败，跳过兜底逻辑');
      return;
    }

    if (!currentConfig.enable) {
      logger.verbose('兜底话术配置已禁用，跳过兜底逻辑');

      return;
    }
    let segments: any = [];
    let currentConfigTemplate: any = [];
    currentConfig.template.forEach((item, index) => {
      if (index === 0) currentConfigTemplate = item;
    });
    if (currentConfigTemplate.segments) segments = currentConfigTemplate.segments;
    const commands = createCommandsFromSegments(segments);

    if (commands.length === 0) {
      logger.warn('话术片段没有可执行的命令: %o', segments);

      return;
    }

    let assistant: Mine;

    try {
      assistant = await getAssistant();
    } catch (error) {
      logger.error('获取客服信息失败: %s', error);

      return;
    }

    let sessions: FolderSession[] = [];

    try {
      sessions = await getAllSessions();
    } catch (error) {
      logger.error('获取所有对话信息失败: %s', error);

      return;
    }

    if (sessions.length === 0) {
      logger.verbose('当前没有对话信息');

      return;
    }

    logger.info('sessions:', sessions);
    const replayMinTime = currentConfig.reply_time * 1000;

    for (const session of sessions) {
      const { folder, userId, userNick, time, __localLightState: localLightState } = session;

      // 2: 请在5分钟内回复 5: 已超时
      // FIXME: __localLightState: 本地亮灯状态，本地亮灯状态表示执行兜底后此 session 未发送变化，此实现依赖 session 对象是被全量更新的假设
      if ((folder === '2' || folder === '5') && !localLightState) {
        const count = userSendCache.get(userId, 0);

        if (count < currentConfig.max_count) {
          const diff = Date.now() - time.valueOf();

          if (diff >= replayMinTime && diff < replyMaxTime) {
            if (!executingSet.has(userId)) {
              executingSet.add(userId);
              for (const command of commands) {
                if (command.type === 'text') {
                  command.text += `\u200B\u200B`;
                }
              }
              const context = event('KS_EXTENSION_FALLBACK_ANSWER_SEND', {
                assistantId: assistant.userId,
                assistantNick: assistant.userNick,
                buyerId: userId,
                buyerNick: userNick,
                commands,
              });
              let noticeMessage: any[] = [];
              const localNoticeMessage = localStorage.getItem('NoticeMessage');
              logger.info('localStorage noticeMessage1', localNoticeMessage);
              if (localNoticeMessage) {
                const localNoticeMessageArr: any[] = JSON.parse(localNoticeMessage);
                localNoticeMessageArr.forEach((item, index, localNoticeMessageArr) => {
                  if (item.buyer_nick === userNick) {
                    localNoticeMessageArr.splice(index, 1);
                  }
                });
                noticeMessage = noticeMessage.concat(localNoticeMessageArr);
              }
              logger.info('localStorage noticeMessage2', noticeMessage);
              const noticeMessageNew = [
                {
                  id: Date.now(),
                  /* eslint-disable camelcase */
                  msg_type: 2,
                  assistant_nick: assistant.userNick,
                  buyer_nick: userNick,
                  plain_text: '兜底消息',
                  update_time: Date.now(),
                  /* eslint-enable camelcase */
                },
              ];
              noticeMessage = noticeMessage.concat(noticeMessageNew);
              localStorage.setItem('NoticeMessage', JSON.stringify(noticeMessage));
              logger.info('localStorage noticeMessage3', noticeMessage);
              let index = 0;
              let commandSuccessCount = 0;

              for (const command of commands) {
                switch (command.type) {
                  case 'text': {
                    try {
                      await sendText(userId, command.text, {
                        mine: assistant,
                        receiver: session,
                      });

                      commandSuccessCount += 1;

                      logger.info(
                        '发送文本消息到买家 "%s@%s" ("%s")',
                        userNick,
                        userId,
                        command.text,
                      );

                      context.event('KS_EXTENSION_FALLBACK_ANSWER_SEND_SUCCESS', {
                        commandType: command.type,
                        commandIndex: index,
                      });
                    } catch (error) {
                      logger.error(
                        '发送文本消息到买家 "%s@%s" ("%s") 失败: %s',
                        userNick,
                        userId,
                        command.text,
                        error,
                      );

                      context.event('KS_EXTENSION_FALLBACK_ANSWER_SEND_FAIL', {
                        commandType: command.type,
                        commandIndex: index,
                        message: error.messgae,
                      });
                    }

                    break;
                  }
                  case 'image': {
                    try {
                      await sendImage(userId, command.url, { mine: assistant, receiver: session });

                      commandSuccessCount += 1;

                      logger.info(
                        '发送图片消息到买家 "%s@%s" ("%s")',
                        userNick,
                        userId,
                        command.url,
                      );

                      context.event('KS_EXTENSION_FALLBACK_ANSWER_SEND_SUCCESS', {
                        commandType: command.type,
                        commandIndex: index,
                      });
                    } catch (error) {
                      logger.error(
                        '发送图片消息到买家 "%s@%s" ("%s") 失败: %s',
                        userNick,
                        userId,
                        command.url,
                        error,
                      );

                      context.event('KS_EXTENSION_FALLBACK_ANSWER_SEND_FAIL', {
                        commandType: command.type,
                        commandIndex: index,
                        message: error.messgae,
                      });
                    }

                    break;
                  }
                  default: {
                    logger.warn('不支持的命令: %o', command);
                  }
                }

                index += 1;
              }

              try {
                // 消息发送完后会自动灭灯，等待足够时间再将灯亮起来
                await wait(1250);
                // await wait(2000);
                await light(userId, true);
                logger.info('买家 "%s@%s" 亮灯', userNick, userId);
              } catch (error) {
                logger.error('买家 "%s@%s" 亮灯失败: %s', userNick, userId, error);
              }

              const nextCount = count + 1;

              userSendCache.set(userId, nextCount);

              logger.verbose('买家 "%s@%s" 第 %s 次执行兜底话术逻辑', userNick, userId, nextCount);

              context.event('KS_EXTENSION_FALLBACK_ANSWER_SEND_FINISH', {
                executeCount: nextCount,
                commandCount: commands.length,
                commandSuccessCount,
              });

              executingSet.delete(userId);
            } else {
              logger.warn('买家 "%s@%s" 兜底正在执行中，跳过兜底逻辑', userNick, userId);
            }
          } else {
            logger.verbose(
              '买家 "%s@%s" 不在可兜底时间区间内: [%sms - %sms]',
              userNick,
              userId,
              replayMinTime - diff,
              replyMaxTime - diff,
            );
          }
        } else {
          logger.verbose(
            '买家 "%s@%s" 已达到最多兜底回复次数 (%s) 跳过兜底逻辑',
            userNick,
            userId,
            currentConfig.max_count,
          );
        }
      } else {
        logger.verbose('买家 "%s@%s" 已经被回复过，跳过兜底逻辑', userNick, userId);
      }
    }
  }

  logger.info('执行首次兜底话术配置同步');

  sync();

  logger.info('执行首次兜底话术逻辑');

  execute();

  const syncIntervalID = setInterval(() => {
    logger.info('执行定时同步兜底话术配置');

    sync();
  }, syncIntervalTime);

  const executeIntervalID = setInterval(() => {
    logger.info('执行定时兜底话术逻辑');

    execute();
  }, executeIntervalTime);

  // 启动websocket

  const bot = new Bot();
  bot.start();

  return () => {
    logger.info('停止 IM 业务逻辑');
    clearInterval(syncIntervalID);
    clearInterval(executeIntervalID);
  };
}

export default setupIM;
