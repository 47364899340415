import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { selector, atom, useRecoilValue, useRecoilState } from 'recoil';
import postToBackground, { Payloads } from 'utils/postToBackgound';

const getCurrentBuyer = () => {
  const buyerNickDom = document.querySelector(
    '#entry_pc > div.middle-area > div > div.user-chat-head  div.username',
  );
  if (buyerNickDom) {
    const nick = buyerNickDom.innerHTML || '';
    return { nick };
  }
  return null;
};
const getAssistantInfo = (isSubAccount: boolean) => {
  const info = { isMain: !isSubAccount } as Payloads['KS_IM_INFO'];
  const $seller = document.querySelector('.left-main .seller-info .seller-name');
  // eslint-disable-next-line no-console
  console.log('$seller: ', $seller);

  if ($seller?.innerHTML) {
    const nick = $seller.innerHTML.replace(/(.+)的店铺$/, '$1');
    info.sellerNick = nick;
    info.assistantNick = nick;
  }
  if (isSubAccount) {
    const $assistantNick = document.querySelector('.left-main .seller-info .user-name');
    if ($assistantNick) {
      const id = $assistantNick.getAttribute('title') || undefined;
      info.assistantNick = $assistantNick.innerHTML;
      info.assistantId = id;
      // eslint-disable-next-line no-console
      console.log('$assistantNick: ', $assistantNick.innerHTML, id);
    }
  }
  // eslint-disable-next-line no-console
  console.log('$info: ', info);
  return info;
};

const currentBuyerState = atom<null | { nick: string }>({
  key: 'ks_state_current_buyer',
  default: null,
});
const isSubAccountState = selector({
  key: 'ks_state_is_sub_account',
  get: () => {
    const cookie = document.cookie?.split(';');
    if (cookie) {
      for (const itemStr of cookie) {
        const [key, value] = itemStr.split('=');
        if (key.trim() === 'sid') {
          return value.startsWith('kshop.api');
        }
      }
    }
    return false;
  },
});
const assistantState = atom<null | Payloads['KS_IM_INFO']>({
  key: 'ks_state_assistant',
  default: null,
});
const useKsState = () => {
  const isSubAccount = useRecoilValue(isSubAccountState);
  const [currentBuyer, setCurrentBuyer] = useRecoilState(currentBuyerState);
  const [assistant, setAssistant] = useRecoilState(assistantState);
  const sync = useCallback(() => {
    const buyer = getCurrentBuyer();
    setCurrentBuyer(buyer);
  }, [setCurrentBuyer]);
  useEffect(() => {
    if (assistant && assistant.assistantId !== '0') {
      return;
    }
    const config = { attributes: true, childList: true, subtree: true };
    const obFn = debounce((mutationsList, observer) => {
      const nextAssistant = getAssistantInfo(isSubAccount);
      if (nextAssistant && nextAssistant.assistantNick) {
        setAssistant(nextAssistant);
        const payload: Payloads['KS_IM_INFO'] = {
          isMain: !isSubAccount,
          assistantNick: nextAssistant.assistantNick,
          assistantId: nextAssistant.assistantId,
        };
        postToBackground('KS_IM_INFO', payload);
        observer.disconnect();
      }
    }, 300);
    const observer = new MutationObserver(obFn);
    observer.observe(document, config);
  }, [assistant, isSubAccount, setAssistant]);
  return {
    sync,
    isSubAccount,
    currentBuyer,
    setCurrentBuyer,
    assistant,
  };
};
export default useKsState;
