import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { ReactComponent as EmptySvg } from './assets/empty.svg';

const styles = {
  container: css`
    text-align: center;
    font-size: 14px;
    color: #666;
  `,
  img: css`
    width: 60px;
    height: auto;
    margin-bottom: 8px;
  `,
  fallback: css`
    min-height: 50px;
  `,
};
export interface EmptyProps {
  description?: string;
  className?: string;
  loading?: boolean;
}
const Empty: React.FC<EmptyProps> = ({ className, description = '暂无数据', loading }) => {
  return loading ? (
    <div className={styles.fallback} />
  ) : (
    <div className={cx(styles.container, className)}>
      <EmptySvg className={styles.img} />
      <p>{description}</p>
    </div>
  );
};
export default React.memo(Empty);
