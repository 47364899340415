import { atom, useRecoilState } from 'recoil';
import { authorize as authorizeApi } from '../../api';

const tokenState = atom<string | null>({
  key: 'auth_token_state',
  default: null,
});

const useAuthorization = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const auth = (token: string) => {
    setToken(token);
    authorizeApi(token);
  };
  const unAuth = () => {
    setToken(null);
    authorizeApi('');
  };
  return {
    authed: !!token,
    token,
    auth,
    unAuth,
  };
};

export default useAuthorization;
