import Dayjs from 'dayjs';

import logger from './logger';

/**
 * 缓存值
 */
interface CacheValue {
  /**
   * 发送次数
   */
  count: number;
  /**
   * 缓存首次写入时间
   */
  ts: number;
}

/**
 * 判断目标对象是否是缓存值
 * @param target 目标对象
 */
function isCacheValue(target: any): target is CacheValue {
  return Boolean(
    target &&
      typeof target.count === 'number' &&
      !Number.isNaN(target.count) &&
      typeof target.ts === 'number' &&
      !Number.isNaN(target.ts),
  );
}

/**
 * 买家发送次数缓存
 */
class UserSendCache {
  /**
   * 存储
   */
  private _storage: Storage;

  /**
   * 前缀
   */
  private _prefix: string;

  /**
   * 构造函数
   * @param storage 存储
   * @param prefix 前缀
   */
  constructor(storage: Storage, prefix: string = 'user-send') {
    this._storage = storage;
    this._prefix = prefix;
  }

  /**
   * 获取存储键名
   * @param userId 买家 ID
   */
  private _getKey(userId: number) {
    return `${this._prefix}#${userId}`;
  }

  /**
   * 访问存储值
   * @param userId 买家 ID
   * @param defaultCount 默认发送次数，默认值未 `0`
   */
  private _accessValue(userId: number, defaultCount: number = 0) {
    const key = this._getKey(userId);
    const raw = this._storage.getItem(key);

    if (raw) {
      try {
        const value = JSON.parse(raw) as any;

        if (isCacheValue(value)) {
          if (Dayjs().isSame(value.ts, 'day')) {
            return value;
          }

          logger.info('买家 "%s" 缓存数据已失效: %o', userId, value);
        } else {
          logger.warn('买家 "%s" 缓存数据格式错误: %o', userId, value);
        }
      } catch (error) {
        logger.error('买家 "%s" 缓存数据解析错误: %s', userId, error);
      }

      this._storage.removeItem(key);

      logger.verbose('移除买家 "%s" 无效缓存', userId);
    } else {
      logger.verbose('买家 "%s" 没有缓存数据', userId);
    }

    return {
      count: defaultCount,
      ts: Date.now(),
    };
  }

  /**
   * 获取买家发送次数
   * @param userId 买家 ID
   * @param defaultValue 默认值，默认为 `0`
   */
  get(userId: number, defaultValue: number = 0) {
    const { count } = this._accessValue(userId, defaultValue);

    return count;
  }

  /**
   * 设置买家发送次数
   * @param userId 买家 ID
   * @param count 发送次数
   */
  set(userId: number, count: number) {
    const key = this._getKey(userId);
    const { ts } = this._accessValue(userId);

    this._storage.setItem(key, JSON.stringify({ count, ts }));
  }

  /**
   * 移除买家缓存
   * @param userId 买家 ID
   */
  remove(userId: number) {
    const key = this._getKey(userId);

    this._storage.removeItem(key);
  }

  /**
   * 遍历买家缓存值
   * @param callback 遍历函数，返回 `false` 提前退出遍历
   */
  each(callback: (userId: number) => void | boolean) {
    const userIds: number[] = [];

    for (let index = 0; index < this._storage.length; index += 1) {
      const key = this._storage.key(index);

      if (key && key.indexOf(this._prefix) === 0) {
        const userId = Number(key.slice(this._prefix.length + 1));

        if (!Number.isNaN(userId)) {
          userIds.push(userId);
        }
      }
    }

    let keep: boolean | void;

    for (const userId of userIds) {
      keep = callback(userId);

      if (keep === false) {
        break;
      }
    }

    return keep;
  }

  /**
   * 清理无效缓存
   */
  prune() {
    this.each((userId) => {
      try {
        this._accessValue(userId);
      } catch (error) {
        logger.error('访问买家 "%s" 缓存错误: %s', userId, error);
      }
    });

    logger.info('清理失效缓存完成');
  }
}

const userSendCache = new UserSendCache(sessionStorage);

export default userSendCache;
