/**
 * 图片尺寸
 */
export interface ImageSize {
  /**
   * 宽度
   */
  width: number;
  /**
   * 高度
   */
  height: number;
}

/**
 * 加载图片资源
 * @param url 图片地址
 */
export function loadImage(url: string) {
  return fetch(url).then((response) => response.blob());
}

/**
 * 获取图片尺寸
 * @param url 图片地址
 */
export function getImageSize(url: string) {
  return new Promise<ImageSize>((resolve, reject) => {
    const $img = new Image();

    $img.onload = () => {
      resolve({
        width: $img.width,
        height: $img.height,
      });
    };

    $img.onerror = () => {
      reject(new Error(`Get image size from "${url}" failed.`));
    };

    $img.src = url;
  });
}
