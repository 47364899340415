import React, { useEffect, useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash';

export type AutoCollaspedOptions = {
  reserveWidth?: number;
  deps?: React.DependencyList;
};
const useAutoCollasped = (
  ref: React.RefObject<HTMLDivElement>,
  options: AutoCollaspedOptions = {},
) => {
  const { current: parent } = ref;
  const [childrenWidths, setChildrenWidths] = useState<number[]>([]);
  const [parentOffset, setParentOffset] = useState({ width: 0 });
  const [count, setCount] = useState(0);
  const [overflow, setOverflow] = useState(false);
  const { reserveWidth = 0, deps = [] } = options;
  useEffect(() => {
    const { width: parentWidth } = parentOffset;
    if (!parentWidth) {
      return;
    }
    const parentPadding = 32; // TODO: calc it from Computedstyle
    const contentWidth = parentWidth - parentPadding - reserveWidth;
    const childMargin = 10; // TODO: calc it from Computedstyle
    let totalWidth = 0;
    let showCount = 0;
    let overflow = false;
    for (const width of childrenWidths) {
      const cWidth = width + childMargin;
      const nextTotalWidth = totalWidth + cWidth;
      overflow = nextTotalWidth > contentWidth;
      if (overflow) {
        break;
      }
      totalWidth = nextTotalWidth;
      showCount += 1;
    }
    setCount(showCount);
    setOverflow(overflow);
  }, [childrenWidths, parentOffset, reserveWidth]);
  useEffect(() => {
    const onResize = debounce(() => {
      if (parent?.getBoundingClientRect) {
        const { width: parentWidth } = parent.getBoundingClientRect();
        setParentOffset({ width: parentWidth });
      }
    }, 500);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [parent]);
  useLayoutEffect(() => {
    const children = parent?.children;
    if (children && parent?.getBoundingClientRect) {
      const { width: parentWidth } = parent.getBoundingClientRect();
      setParentOffset({ width: parentWidth });
      const nextChildrenWidths: number[] = [];
      for (let idx = 0; idx < children.length; ) {
        const c = children[idx];
        if (c?.getBoundingClientRect) {
          const { width } = c.getBoundingClientRect();
          nextChildrenWidths.push(width);
        }
        idx += 1;
      }
      setChildrenWidths(nextChildrenWidths);
    } else {
      setCount(0);
      setChildrenWidths([]);
      setParentOffset({ width: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, ...deps]);
  return { overflow, count, childrenWidths };
};
export default useAutoCollasped;
