/* eslint-disable camelcase */

import { ksClient } from '.';

/**
 * 响应对象
 */
interface Response<T> {
  /**
   * 业务状态码
   */
  sub_code: string;
  /**
   * 响应消息
   */
  msg: string;
  /**
   * 返回结果
   */
  data: T;
}

/**
 * 文本话术片段
 */
export interface TextSegment {
  /**
   * 话术片段类型
   */
  type: 'text';
  /**
   * 文本内容
   */
  text: string;
}

/**
 * 图片话术片段
 */
export interface ImageSegment {
  /**
   * 话术片段类型
   */
  type: 'image';
  /**
   * 图片 ID
   */
  image_id: string;
  /**
   * 图片地址
   */
  src: string;
}

/**
 * 分割符话术片段
 */
export interface EndOfSentenceSegment {
  /**
   * 话术片段类型
   */
  type: 'end_of_sentence';
}

/**
 * 话术片段
 */
export type Segment = TextSegment | ImageSegment | EndOfSentenceSegment;

/**
 * 兜底配置
 */
export interface GuaranteeConfig {
  /**
   * 是否启用
   */
  enable: boolean;
  /**
   * 兜底执行时间 (分钟)
   */
  reply_time: number;
  /**
   * 买家最多兜底次数
   */
  max_count: number;
}

/**
 * 兜底话术模板
 */
export interface Template {
  /**
   * 话术 ID
   */
  id: string;
  /**
   * 话术片段
   */
  segments: Segment[];
}

export type GuaranteeTemplate = Template[];

/**
 * 获取兜底配置
 */
export function fetchGuaranteeConfig() {
  return ksClient.get<Response<GuaranteeConfig>>(
    '/store_home/store/v3/guarantee_config?type=CUSTOMIZE',
  );
}

/**
 * 获取兜底话术模板
 */
export function fetchGuaranteeTemplate() {
  return ksClient.get<Response<GuaranteeTemplate>>(
    '/store_home/store/v3/guarantee_template?type=CUSTOMIZE',
  );
}
