import logger from 'services/logger';
import { CommandSendText, CommandSendImage } from './commands';

function pickOne(handlers: Record<string, Function>, oneOfStruct: Record<string, any>) {
  for (const key of Object.keys(handlers)) {
    const attr = oneOfStruct[key];
    if (attr && handlers[key]) {
      return handlers[key](attr);
    }
    logger.error('unknown dialog type %s', key);
  }
  return null;
}

type Mode = 'autoReply' | 'forceSend';

function handleSentence(mode: Mode, sentences: any[]) {
  if (sentences === undefined) {
    return [];
  }

  const handler = {
    text: (cmd: { content: string }) => {
      // if (cmd.content.trim().startsWith('已重置')) {
      //   return new CommandReset();
      // }
      return new CommandSendText(cmd.content, mode);
    },
    image: (cmd: { imageId: string }) => new CommandSendImage(cmd.imageId, mode),
  };
  return sentences.map((sentence) => pickOne(handler, sentence));
}

function flat(commandList: any[]) {
  let commands: any = [];
  for (const cmd of commandList) {
    if (cmd === null) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (Array.isArray(cmd)) {
      commands = [...commands, ...cmd];
    } else {
      commands.push(cmd);
    }
  }
  return commands;
}

const RESPONSE_HANDLERS = Object.freeze({
  replyResponse: (cmd: { sentence: any[] }) => handleSentence('autoReply', cmd.sentence),
  forceSendResponse: (cmd: { sentence: any[] }) => handleSentence('forceSend', cmd.sentence),
});

export default class Convertor {
  // eslint-disable-next-line class-methods-use-this
  convert(args: any) {
    const { userNick, dialogueResponse, jobQueueName: queueName = 'UNKNOWN' } = args;
    const commands = dialogueResponse.map((response: any) => pickOne(RESPONSE_HANDLERS, response));
    return { userNick, commands: flat(commands), queueName };
  }
}
